
import { Component, Vue, Prop, Watch } from 'nuxt-property-decorator'

@Component
export default class NavigationHeader extends Vue {
  @Prop({ default: true }) readonly drawer!: boolean

  @Prop({ default: false }) readonly rightDrawer!: boolean|null

  languages = window.location.href.includes('mk.')
    // eslint-disable-next-line multiline-ternary
    ? [
        {
          code: 'mk',
          name: 'македонски'
        },
        {
          code: 'en',
          name: 'English'
        }
      ] : [
        {
          code: 'bg',
          name: 'Български'
        },
        // {
        //   code: 'mk',
        //   name: 'македонски'
        // },
        {
          code: 'en',
          name: 'English'
        }
      ]

  mounted () {
    if (this.$route.path.includes('mobile-camera') || this.$route.path.includes('traffic-monitoring')) {
      setTimeout(() => {
        this.$emit('update:drawer', false)
      }, 1000)
    }
  }

  @Watch('$route.path')
  onPathChanged (nextVal: string) {
    if (nextVal.includes('mobile-camera') || nextVal.includes('traffic-monitoring')) {
      setTimeout(() => {
        this.$emit('update:drawer', false)
      }, 1000)
    }
  }
}
