export default function ({ app, route, redirect }: any) {
  if (process.client) {
    const isMacedonian = window.location.href.includes('mk') || window.location.href.includes('.demo')
    const newLocale = isMacedonian ? 'mk' : 'bg'

    if (app.i18n.locale !== newLocale) {
      app.i18n.setLocale(newLocale)
      document.cookie = `i18n_redirected=${newLocale};path=/`

      const localizedRoute = app.localePath(route.fullPath, newLocale)
      if (route.fullPath !== localizedRoute) {
        redirect(localizedRoute)
      }
    }
  }
}
