export default {
  validation: {
    alreadyExists: '{0} {1} already exists', // example with placeholders. Don't remove yet
    signup_failed: 'Sign up failed',
    required: 'This field is required',
    email: 'Please enter a valid email format',
    numeric: 'Must be a numeric value',
    minLengthOf: 'Minimum length is {0}',
    maxLengthOf: 'Maximum length is {0}',
    minValue: 'Minimum value is ',
    maxValue: 'Maximum value is ',
    repeatPassword: 'Password does not match',
    notValidRange: 'Value is outside of range',
    alpha: 'Must be a alphabetic value',
    invalid_credentials: 'Invalid credentials',
    strongPassword: 'Must have at least 8 characters with one number and one uppercase letter',
    not_properly_formatted: 'Field is not properly formatted.',
    invalid_phone_number: 'Invalid phone number',
    password_is_weak: 'Must have at least 8 characters with one uppercase letter and a special character',
    password_hint: 'Must have at least 8 characters with one number and one uppercase letter',
    namesMinLength: 'Names must have more than one letter',
    required_field: 'This field is required',
    invalid_country_code: 'Invalid country code',
    internal_server_error: 'Oops, something went wrong',
    valid_phone_number: 'Must be a valid phone number like 359800000000',
    Prices: 'At least one price is required',
    CarSlots: 'At least one car slot is required',
    Borders: 'Please draw area borders',
    no_location: 'Please select an address from the list of suggestions',
    no_result: 'No results',
    cars_cannot_be_more_than: 'Cars cannot be more than ',
    active_cars_cannot_be_more_than: 'Active cars cannot be more than ',
    addresses_cannot_be_more_than: 'Addresses cannot be more than ',
    active_addresses_cannot_be_more_than: 'Active addresses cannot be more than '
  },
  menu: {
    traffic_monitoring: 'Traffic Monitoring',
    mobile_camera_tracker: 'Mobile Camera',
    mobile_camera_activity: 'Mobile cameras FPS',
    comments: 'Comments',
    payment_report: 'Monthly Payments Report',
    tickets_and_cards: 'Tickets & cards',
    users: 'Users',
    employees: 'Employees',
    cameras: 'Cameras',
    trade_network: 'Trade network',
    statistics: 'Statistics',
    settings: 'Settings',
    general_settings: 'General settings',
    cities: 'Cities',
    company: 'Company',
    store: 'Store',
    fine_type: 'Fine type',
    discount: 'Subscriptions discount',
    whitelist_car: 'Whitelist car',
    subscription: 'Subscription',
    subscriptions: 'Subscriptions',
    business_subscriptions: 'Business Subscriptions',
    subscription_plan: 'Subscription plan',
    roles_and_permissions: 'Roles & Permissions',
    user_management: 'User management',
    park_zone: 'Park zone',
    park_area: 'Park area',
    mc_park_area: 'Mobile Camera Park area',
    payment_statistics: 'Payments',
    translations: 'Translations',
    translations_city: 'For cities',
    translations_common: 'Common',
    daily_card: 'Daily cards',
    tickets: 'Tickets',
    sms: 'SMS',
    non_working_days: 'Non-working days',
    incidents: 'Incidents',
    active_incidents: 'Active incidents',
    past_incidents: 'Past incidents',
    finished_incidents: 'Finished incidents',
    fine: 'Fines',
    fines: 'Fines',
    payments: 'Payments',
    reports: 'Reports',
    repatriator_activity: 'Repatriator activity',
    inspector_activity: 'Inspector activity',
    tyreblocker_activity: 'Tyreblocker activity',
    employees_statistics: 'Employees Statistics',
    camera_activity: 'Camera activity',
    parking: 'Parking',
    repatriation_incidents: 'Repatriation',
    camera_inspections: 'Camera Inspections',
    inspection_reviews: 'Inspection Reviews',
    inspections: 'Inspections',
    mobile_camera: 'Mobile Cameras'
  },
  btn: {
    undo_finalize: 'Undo Finalize',
    download_qrcode: 'Download QRCode',
    finalize: 'Finalize',
    mark_as_trusted: 'Mark as trusted',
    mark_as_not_trusted: 'Mark as not trusted',
    cameras: 'Cameras',
    detections: 'Detections',
    statistics: 'Statistics',
    mobile_camera_logs: 'Mobile camera logs',
    yes: 'Yes',
    no: 'No',
    mark_as_paid: 'Mark as paid',
    release_car: 'Release car',
    print: 'Print',
    print_table: 'Print table',
    print_images: 'Print images',
    print_combined: 'Print all',
    cancel_fine: 'Cancel fine',
    preview_incident: 'Preview incident',
    export: 'Export',
    profile: 'Profile',
    change_password: 'Change password',
    logout: 'Logout',
    clear_all_cache: 'Clear All Caches',
    re_init_rbac: 'Re-init RBAC',
    login: 'Login',
    forgotten_password: 'Forgot password',
    submit: 'Submit',
    create: 'Create',
    cancel: 'Cancel',
    decline_request: 'Decline Request',
    close: 'Close',
    update: 'Update',
    preview: 'Preview',
    update_license: 'Update license',
    remove: 'Remove',
    clear: 'Clear',
    actions: 'Actions',
    copy: 'Copy',
    approve: 'Approve',
    pay: 'Pay',
    reverse_pay: 'Reverse payment',
    pay_with_cash: 'Pay with cash',
    pay_with_wire: 'Pay with wire',
    cancel_payment: 'Cancel payment',
    payments: 'Payments',
    cancelSubscription: 'Cancel subscription',
    details: 'Details',
    logs: 'Logs',
    assign_hourly_prices: 'Assign hourly prices',
    assign_daily_prices: 'Assign daily prices',
    add_new_price: 'add new price',
    add_new_duration: 'Add duration',
    reverse_cancel: 'Reverse cancel',
    release_request: 'Create release request',
    no_available_actions: 'No available actions',
    view_subscription: 'View details',
    add_another: 'Add another',
    add_car: 'Add car',
    add_address: 'Add address',
    add_price: 'Add price',
    confirm: 'Confirm',
    reject: 'Reject',
    check: 'Check',
    select: 'Select',
    add_missing_car: 'Add missing or undetected car',
    images: 'images',
    load: 'Load',
    revert: 'Revert',
    accessible_parking_slot: 'Accessible parking slot',
    paid_parking_space: 'Paid parking space',
    outside_zone: 'Outside of zone',
    rejected: 'Reject'
  },
  form: {
    send_to_mateus: 'Send to Mateus',
    ReportIsFinalized: 'Report is finalized you cannot change the rows now',
    PaymentReport: 'Payment Report',
    NewAmount: 'New Amount',
    cannot_modify_address_slots: 'You cannot add or modify address slots after the subscription is approved',
    select_payment_method: 'Select your payment method',
    template: 'Template',
    BusinessSubscriptionNotifications: 'Business Subscription Notifications',
    print_document: 'Print document',
    print_help_text: 'Scroll down this dialog to the bottom (1), click the icon next to the percentage bar, select "Отпечатване в PDF" (Print to PDF) from the menu (2), then click Отвори PDF',
    how_to_print: 'How to print',
    generate_pdf: 'Generate Document',
    download_pdf: 'Download PDF',
    mark_as_sent: 'Mark as sent',
    mark_as_received: 'Mark as received',
    mark_as_not_received: 'Mark as not received',
    resend: 'Resend',
    addresses: ' addresses',
    addressSlots: 'Address Slots',
    received_second_try: 'Received on second try',
    not_received_second_try: 'Not received on second try',
    notification: 'Notification',
    ticket: 'Ticket',
    download: 'Download',
    camera_actions_hint: 'Select cameras to perform actions',
    user_icon: 'Inspector',
    cancelled_icon: 'Cancel location',
    AccessibleParkingSpots: 'Accessible Parking Spots',
    PaidParkingSpots: 'Paid Parking Spots',
    DriverFirstName: 'Driver first name',
    DriverLastName: 'Driver last name',
    TrustedTyreBlocker: 'Trusted Tyreblocker',
    CreateFinePrinterTextID: 'Create fine printer text',
    ReleaseCarPrinterTextID: 'Release car printer text',
    Forever: 'Forever',
    Converted: 'Converted fine',
    FullName: 'Full name',
    Variables: 'Variables',
    Text: 'Text',
    Lang: 'Language',
    Active: 'Active',
    Inactive: 'Inactive',
    fine: 'fine',
    Source: 'Source',
    ID: 'ID',
    tyreblocker_activity: 'tyreblocker activity',
    payment_fine: 'payment fine',
    payment_parking: 'payment parking',
    employee_app_inspection: 'employee app inspection',
    employee_app_fine: 'employee app fine',
    sms: 'sms',
    incident: 'Incident',
    payment_subscription: 'payment subscription',
    employee_app_tyre_block: 'employee app tyre block',
    inspector_activity: 'inspector activity',
    FromPhone: 'From phone',
    ParkZones: 'Park Zones',
    ToPhone: 'To phone',
    preview: 'Preview',
    city: 'city',
    translation: 'translation',
    company: 'company',
    store: 'store',
    fine_type: 'fine type',
    discount: 'discount',
    whitelist_car: 'whitelist car',
    subscription_plan: 'subscription plan',
    subscription: 'subscription',
    role: 'role',
    admin_user: 'Admin User',
    park_zone: 'park zone',
    park_area: 'park area',
    payment_statistic: 'payment statistic',
    tickets: 'tickets',
    daily_card: 'daily card',
    non_working_days: 'non working days',
    SubscriptionDocuments: 'Subscription documents',
    SubscriptionPayments: 'Subscription payments',
    create: 'Create',
    edit: 'Edit',
    Email: 'Email',
    Password: 'Password',
    Name: 'Name',
    Tolerance: 'Tolerance',
    RepatriationAfterTyreBlockInMinutes: 'Repatration after (min)',
    ExpireParkingReminderAfterInMinutes: 'Expiration reminder (min)',
    Status: 'Status',
    SMSProviderMobicaSettings: {
      Username: 'Mobica username',
      Password: 'Mobica password'
    },
    TimeZoneID: 'Time zone',
    DefaultLanguage: 'Default language',
    CountryID: 'Country',
    HasSubscriptions: 'Subscriptions',
    HasDailyCards: 'Daily cards',
    PaymentProviderIDs: 'Payment providers',
    SMSProviderID: 'SMS provider',
    SMSProviderLinkMobilitySettings: {
      Shortcode: 'Shortcode',
      Service: 'Service',
      Secret: 'Secret',
      Key: 'Key'
    },
    CityID: 'City',
    Address: 'Address',
    OwnerName: 'Owner name',
    MOL: 'MOL',
    Phone: 'Phone',
    VAT: 'VAT',
    SellerCompanyID: 'Seller company',
    PostalCode: 'Postal code',
    ContactPerson: 'Contact person',
    Manager: 'Manager',
    FineType: 'Fine type',
    GratisHours: 'Gratis hours',
    Price: 'Price',
    PriceDiscount: 'Price discount',
    PriceForTyreBlock: 'Price for tyre block',
    PriceStayingAtParking: 'Price for staying at parking',
    PriceStayingAtParkingType: 'Price for staying at parking type',
    RequiresPhotos: 'Requires photos',
    RequiresComment: 'Requires comment',
    NumberOfPayments: 'Number of payments',
    Type: 'Type',
    DiscountPrice: 'Discount price',
    DiscountPercentage: 'Discount percentage',
    License: 'License',
    ExpireAt: 'Expires at',
    Reason: 'Reason',
    Note: 'Note',
    SubscriptionPlanID: 'Subscription plan',
    FirstName: 'First name',
    MiddleName: 'Middle name',
    LastName: 'Last name',
    PersonalID: 'Personal ID',
    UserAddress: 'Address',
    ParkAreaIDs: 'Park area',
    ParkZoneIDs: 'Park zone',
    SelectAll: 'Select all',
    CompanyAddress: 'Company address',
    StartDate: 'Start date',
    EndDate: 'End date',
    SubscriptionPlanPriceID: 'Subscription plan price',
    IsActive: 'Active',
    SubmissionNumber: 'Submission number',
    ThirdPartyID: 'Third party',
    Duration: 'Duration (months)',
    DurationMinutes: 'Duration (minutes)',
    DurationDays: 'Duration (days)',
    CarSlotType: 'Car slot type',
    MaxAllowedCarSlots: 'Max allowed rows',
    MaxAllowedActiveCarSlots: 'Max allowed active rows',
    PriceName: 'Price name',
    ExpireSubscriptionReminderInMinutes: 'Expire subscription reminder (minutes)',
    ExpireSubscriptionReminderInDays: 'Expire subscription reminder (days)',
    PaymentSubscriptionReminderInDays: 'Payment subscription reminder (days)',
    DiscountIDs: 'Discounts',
    ShouldBeApproved: 'Should be approved',
    IsFixedDuration: 'Fixed duration',
    UserFullName: 'User full name',
    Prices: 'Prices',
    parking_lot: 'Parking lot',
    car_number: 'Car number',
    up_to: 'Up to',
    infinite: 'Infinite',
    CarSlots: 'Car slots',
    SubscriptionID: 'Subscription ID',
    Amount: 'Amount',
    Discount: 'Discount',
    Total: 'Total',
    hasUserCompany: 'Public company',
    Permissions: 'Permissions',
    RoleID: 'Role',
    FlowbirdID: 'Flowbird ID',
    MaxParkingHours: 'Max parking hours',
    ParkZoneSchedule: 'Park Zone Schedule',
    AllowFullDayPark: 'Allow full day park',
    SMSCode: 'SMS code',
    SMSNumber: 'SMS number',
    Capacity: 'Capacity',
    ParkZoneID: 'Park zone',
    OldPassword: 'Old password',
    NewPassword: 'New password',
    NewPasswordConfirmation: 'Repeat new password',
    PasswordConfirmation: 'Repeat Password',
    outgoing_sms_provider: 'Outgoing SMS provider',
    general: 'General',
    parking_settings: 'Parking settings',
    Day: 'Day',
    From: 'From',
    To: 'To',
    Category: 'Category',
    EditorType: 'Editor type',
    Translations: 'Translations',
    Number: 'Number',
    TicketNumber: 'Ticket number',
    StoreID: 'Store',
    end_of_day: 'End of day',
    same_time: 'Same time',
    FromDate: 'From date',
    ToDate: 'To date',
    Key: 'Key',
    AdminUserID: 'Inspector name',
    CanceledAdminUserID: 'Cancellation inspector',
    PaymentProviderMethod: 'Payment method',
    FineTypeID: 'Fine type',
    CheckMethod: 'Check method',
    PaymentProviderCode: 'Payment provider code',
    InspectionCreatedAt: 'Time of inspection',
    Accuracy: 'GPS Accuracy',
    ParkAreaName: 'Park area name',
    StoreTicketsAndDailyCards: 'Store tickets and daily cards',
    Payments: 'Payments',
    TodayPayments: 'Today payments',
    NextDayPayments: 'Next day payments',
    WhiteLists: 'Whitelists',
    ActiveSubscriptions: 'Active subscriptions',
    InactiveSubscriptions: 'Inactive subscriptions',
    employee_app_cash: 'Cash',
    revenue_agency: 'Revenue agency',
    employee_app_wire: 'Wire',
    Comment: 'Comment',
    PaymentCode: 'Payment method',
    PriceOnField: 'Price on field',
    PriceOnFieldCheckbox: 'Payment on field',
    PaidOnFieldPrinterTextID: 'Printer text on field',
    PaidOnFieldPrinterTextIDCheckbox: 'Has printer text on field',
    MaxPricePerDay: 'Maximum price per day',
    FineTypeIDs: 'Fine types list',
    TotalDays: 'Days',
    TotalHours: 'Hours',
    TotalPrice: 'Total amount',
    CameraID: 'Camera ID',
    ParkingLotID: 'Parking Lot',
    Trusted: 'Trusted',
    NotTrusted: 'Not Trusted',
    Inspections: 'Inspections',
    Details: 'Details',
    OriginalLicense: 'Original License',
    Photo: 'Photo',
    ParkZoneName: 'Park Zone',
    ParkingLotName: 'Parking Lot',
    ParkingSpaceName: 'Parking Space',
    Location: 'Location',
    OriginalAddress: 'Original Address',
    City: 'City',
    cant_change_moderation_status: 'The inspection is already moderated by another user',
    inspection_image: 'Inspection Image',
    live_camera_image: 'Live Camera Image',
    tyreblock_images: 'Tyrblock Images',
    repo_images: 'Repatration Images',
    image: 'Image',
    unblocked: 'Unblocked',
    release_request_created: 'Release Request Created',
    calculate_fine_from: 'Calculate Fine From',
    default: 'Default',
    enabled: 'Enabled',
    disabled: 'Disabled',
    printing: 'Printing',
    disableSubscriptionEmail: 'Disable Subscription Email',
    disableSubscriptionSMS: 'Disable Subscription SMS',
    paymentAfterExpiredTolerance: 'Allow Payment after expired tolerance',
    ParentIDs: 'Parents',
    inspection: 'Inspection',
    camera: 'Camera'
  },
  table: {
    trusted: 'Trusted',
    business_subscription: 'Business Subscription',
    not_trusted: 'Not Trusted',
    accessible_parking_slot: 'Accessible parking slot',
    paid_parking_space: 'Paid parking space',
    outside_zone: 'Outside of zone',
    rejected: 'Reject',
    invisible: 'Incomplete/Invisible number',
    LastUpdate: 'Last location',
    accepted_requests: 'Accepted requests',
    paid_on_field: 'Paid on field',
    repatriated: 'Repatriated',
    Environment: 'Environment',
    Version: 'Version',
    Link: 'Link',
    Download: 'Download',
    Changes: 'Changes',
    Lang: 'Language',
    Key: 'Key',
    Text: 'Text',
    Comment: 'Comment',
    PersonalID: 'Personal ID',
    CarSlots: 'Cars',
    ParkAreas: 'Park areas',
    ticket_machine: 'Ticket machine',
    Source: 'Source',
    TypeName: 'Type name',
    PlanName: 'Plan',
    Active: 'Is active car',
    PaidTo: 'Paid to',
    success: 'Valid',
    failed: 'Invalid',
    RawLicense: 'Необработен рег. номер',
    cash: 'Cash',
    SMSProvider: 'SMS provider',
    FromPhone: 'From phone',
    ToPhone: 'To phone',
    ID: 'ID',
    Team: 'Team',
    FineTypeName: 'Fine type',
    UserName: 'User name',
    Status: 'Status',
    Name: 'Name',
    Company: 'Company',
    Type: 'Type',
    Price: 'Price',
    Percentage: 'Percentage',
    License: 'License',
    City: 'City',
    Reason: 'Reason',
    ExpireAt: 'Expires at',
    UpdatedAt: 'Updated at',
    CreatedAt: 'Created at',
    Actions: 'Actions',
    Amount: 'Amount',
    pending_review: 'Pending review',
    pending_payment: 'Pending payment',
    declined: 'Declined',
    expired: 'Expired',
    canceled: 'Cancelled',
    not_paid: 'Not paid',
    Role: 'Role',
    Email: 'Email',
    payment_provider: 'Payment provider',
    payment_count: 'Payment count',
    payment_amount: 'Payment amount',
    parking_daily: 'Daily parking',
    parking_hourly: 'Hourly parking',
    CityName: 'City name',
    FromDate: 'From date',
    ToDate: 'To date',
    IssuedDate: 'Issued date',
    TicketCount: 'Ticket count',
    TicketAmount: 'Ticket amount',
    DailyCardsCount: 'Daily cards count',
    DailyCardsAmount: 'Daily cards amount',
    DailyCardsTicketsTotalAmount: 'Daily cards tickets total amount',
    CanceledTicketCount: 'Cancelled ticket count',
    CanceledTicketAmount: 'Cancelled ticket amount',
    CanceledDailyCardsCount: 'Cancelled daily cards count',
    CanceledDailyCardsAmount: 'Cancelled daily cards amount',
    CanceledDailyCardsTicketsTotalAmount: 'Cancelled daily cards tickets total amount',
    EditorType: 'Editor type',
    TicketNumber: 'Ticket number',
    Store: 'Store',
    Duration: 'Duration',
    StartDate: 'StartDate',
    EndDate: 'End date',
    Category: 'Category',
    TotalAmount: 'Total amount',
    dailyPriceDuration: 'Duration: {0} days, type: {1}',
    Date: 'Date',
    Phone: 'Phone',
    ShortNumber: 'Short number',
    ParkZoneID: 'Park zone',
    ParkZoneName: 'Park zone',
    ParentIncident: 'Parent incident',
    InspectorName: 'Inspector name',
    Location: 'Location',
    Address: 'Address',
    OriginalAddress: 'Original address',
    TyreBlockName: 'Tyre-block name',
    DueDate: 'Due date',
    CanceledAt: 'Canceled at',
    CanceledInspectorName: 'Cancellation inspector',
    Note: 'Comment',
    Event: 'Event',
    Images: 'Images',
    FineType: 'Fine type',
    PaymentProviderMethod: 'Payment method',
    AdminUserEmail: 'Admin email',
    SubscriptionPlanName: 'Subscription plan',
    SubscriptionOwnerName: 'Subscription owner',
    PaymentMethod: 'Payment method',
    PaymentDue: 'Payment due date',
    CheckMethod: 'Check method',
    Result: 'Result',
    FineStatus: 'Fine status',
    Checks: 'Checks',
    BlockRequest: 'Block request',
    Blocked: 'Blocked',
    NoAction: 'No action',
    Repatration: 'Repatration',
    Repatriation: 'Repatriation',
    Fine: 'Fine',
    FineDiscount: 'Fine discount',
    Canceled: 'Canceled',
    MovedToRepatration: 'Move to repatration',
    MovedToRepatriation: 'Move to repatriation',
    ReleaseRequest: 'Release request',
    BlockedTime: 'Blocked time',
    ReleasedTime: 'Released time',
    sms: 'SMS',
    checked_car: 'Checked car',
    block_request: 'Block request',
    blocked: 'Blocked',
    no_action: 'No action',
    repatriation_requests: 'Repatration requests',
    custom_fine: 'Custom fine',
    fine_discount: 'Fine discount',
    release_request: 'Release request',
    PaymentProviderCode: 'Payment provider code',
    SMSPhone: 'SMS phone',
    ValidUntil: 'Valid until',
    CarID: 'Car ID',
    Method: 'Method',
    Code: 'Code',
    Message: 'Message',
    FirstName: 'First name',
    MiddleName: 'Middle name',
    LastName: 'Last name',
    SubscriptionID: 'Subscription ID',
    fine: 'Fine',
    parking: 'Parking',
    provider: 'Provider',
    total: 'Total',
    Subscription: 'Subscription',
    subscription: 'Subscription',
    ParkingLot: 'Parking Lot',
    LastInspection: 'Last Inspection',
    CameraID: 'Camera ID',
    LastInspectionImage: 'Last Inspection Image',
    valid_checks: 'Valid Checks',
    confirm_edited: 'Confirm Edited',
    tyre_block_requests: 'Tyre Block Requests',
    camera_actions: 'Camera Actions',
    camera_actions_total: 'Total',
    camera_actions_confirmed_edited: 'Corrected',
    camera_actions_confirmed: 'Confirmed',
    camera_actions_rejected: 'Denied',
    camera_actions_expired: 'Expired',
    camera_actions_auto_confirmed: 'Auto Confirmed',
    camera_actions_auto_rejected: 'Auto Rejected',
    camera_actions_pending: 'Pending',
    employee_actions: 'Employee Actions',
    employee_actions_total: 'Total',
    employee_actions_confirmed: 'Confirmed',
    employee_actions_corrected: 'Corrected',
    employee_actions_denied: 'Denied',
    parking_lots: 'Parking lots',
    active_incidents: 'Active Incidents',
    trusted_camera_inspections: 'Trusted Camera Inspections',
    not_trusted_camera_inspections: 'Not Trusted Camera Inspections',
    trusted_mobile_camera_inspections: 'Trusted Mobile Camera Inspections',
    not_trusted_mobile_camera_inspections: 'Not Trusted Mobile Camera Inspections',
    trusted_cameras: 'Trusted Cameras',
    not_trusted_cameras: 'Not Trusted Cameras',
    last: 'Last',
    image_loading_smartalot: 'Loading images from smartAlot',
    wire: 'wire',
    integration: 'integration',
    IsConverted: 'Is Converted',
    ConvertedAt: 'Converted At',
    mobile_camera: 'Mobile Cameras',
    trusted_mobile_camera: 'Trusted Mobile Cameras',
    not_trusted_mobile_camera: 'Not Trusted Mobile Cameras'
  },
  page: {
    apps: 'Mobile applications',
    comments: 'Comments',
    history: 'History',
    mobile_camera_area_information: 'Mobile Camera Area information',
    'camera-management': 'Cameras',
    mobile_camera: 'Mobile Cameras',
    'payments-report': 'Monthly Payments Report',
    login: 'Login',
    forgotten_password: 'Forgot password',
    cities: 'Cities',
    city: 'City',
    store: 'Store',
    monthly_statistics: 'Monthly Report - Summary',
    monthly_employees_statistics: 'Monthly Report - Employees',
    inspectorConfig: 'Inspector Config',
    'fine-type': 'Fine type',
    fine_type: 'Fine type',
    discount: 'Subscriptions discount',
    whitelist_car: 'Whitelist car',
    subscription_plan: 'Subscription plan',
    'subscription-plan': 'Subscription plan',
    'business-subscription': 'Business subscription',
    subscription: 'Subscription',
    company: 'Company',
    'subscription-payments': 'Subscription payments',
    before: 'Before',
    changes: 'Changes',
    role: 'Roles and Permissions',
    'user-management': 'User management',
    'park-zone': 'Park zone',
    'park-area': 'Park area',
    'change-password': 'Change password',
    reset_password: 'Reset password',
    payment_statistics: 'Payment statistics',
    payment_details: 'Payment details',
    daily_card: 'Daily cards',
    translations: 'Translations',
    translations_city: 'Translations - cities',
    translations_common: 'Translations - common',
    tickets: 'Tickets',
    sms: 'SMS',
    non_working_days: 'Non-working days',
    incidents_finished: 'Finished incidents',
    incidents_active: 'Active incidents',
    incidents_past: 'Past incidents',
    incidents_repatriation: 'Incidents - repatriation',
    fine: 'Fines',
    payments_fines: 'Payments - Fines',
    payments_subscriptions: 'Payments - Subscriptions',
    payments_parking: 'Payments - Parking',
    inspector_activity: 'Inspector activity',
    tyreblocker_activity: 'Other Employees Activity',
    inspector_activity_details: 'Inspector activity - details',
    tyreblocker_activity_details: 'Tyreblocker activity - details',
    repatriator_activity: 'Repatriator activity',
    repatriator_activity_details: 'Repatriator activity - details',
    camera_activity: 'Camera Activity',
    camera_activity_details: 'Camera Activity - details',
    camera_incidents_active: 'Camera Active Incidents',
    parking_lot: 'Parking Lot',
    select_image: 'Please select the image',
    no_checks_for_car: 'No checks for this car found for today',
    camera_activity_actions: 'Camera Activity Actions',
    inspection_reviews: 'Inspection Reviews'
  },
  router: {
    '/': 'home',
    '/apps': 'mobile applications',
    '/city': 'city',
    '/company': 'company',
    '/store': 'store',
    '/fine-type': 'fine type',
    '/discount': 'subscriptions discount',
    '/whitelist-car': 'whitelist car',
    '/subscription': 'subscription',
    '/business-subscription': 'business subscription',
    '/subscription-plan': 'subscription plan',
    '/subscription/payments': 'subscription payments',
    '/role': 'role and permissions',
    '/user-management': 'user management',
    '/park-zone': 'park zone',
    '/park-area': 'park area',
    '/mc-park-area': 'mobile camera area',
    '/change-password': 'change password',
    '/statistics/payments': 'payment statistics',
    '/statistics/payments/details': 'details',
    '/store-statistics': 'store statistics',
    '/camera-management': 'camera management',
    '/translations-city': 'translations - cities',
    '/translations-common': 'translations - common',
    '/daily-card': 'daily cards',
    '/tickets': 'tickets',
    '/sms': 'sms',
    '/non-working-days': 'non-working days',
    '/incidents/active': 'active incidents',
    '/inspections/reviews': 'inspections reviews',
    '/incidents/finished': 'finished incidents',
    '/incidents/cameras': 'camera incidents',
    '/incidents/past': 'past incidents',
    '/incidents/repatriation': 'incidents - repatriation',
    '/fines': 'fines',
    '/payments/fines': 'payments - fines',
    '/payments/parking': 'payments - parking',
    '/payments/subscriptions': 'payments - subscriptions',
    '/reports/inspector-activity': 'inspector activity',
    '/reports/tyreblocker-activity': 'tyreblocker activity',
    '/comments': 'comments',
    '/repatriation-incidents/active': 'repatriation - active',
    '/repatriation-incidents/finished': 'repatriation - finished',
    '/repatriation-incidents/past': 'repatriation - past',
    '/reports/repatriator-activity': 'repatriator activity',
    '/reports/camera-activity': 'camera activity',
    '/reports/camera-activity-actions': 'camera activity actions',
    '/employee-reports/monthly-employees': 'Monthly Report (Employees)',
    '/payments-report': 'Monthly Payment Report'
  },
  months: {
    Jan: 'Jan',
    Feb: 'Feb',
    Mar: 'Mar',
    Apr: 'Apr',
    May: 'May',
    Jun: 'Jun',
    Jul: 'Jul',
    Aug: 'Aug',
    Sep: 'Sep',
    Oct: 'Oct',
    Nov: 'Nov',
    Dec: 'Dec'
  },
  day: {
    Monday: 'Monday',
    Tuesday: 'Tuesday',
    Wednesday: 'Wednesday',
    Thursday: 'Thursday',
    Friday: 'Friday',
    Saturday: 'Saturday',
    Sunday: 'Sunday'
  },
  warning: {
    cancelLocationNear: 'The location of the inspection and the location of the cancellation are in close proximity to each other',
    approveSubscriptionTitle: 'Wait! Are you sure you want to proceed?',
    cancelSubscriptionTitle: 'Wait! Are you sure you want to proceed?',
    reverseCancelSubscriptionTitle: 'Wait! Are you sure you want to proceed?',
    confirmDeleteTitle: 'Wait! Are you sure you want to proceed?',
    cancelPaymentTitle: 'Wait! Are you sure you want to proceed?',
    approveSubscriptionDescription: 'Please confirm in order to proceed.',
    cancelPaymentDescription: 'Please confirm in order to proceed.',
    cancelSubscriptionDescription: 'Please confirm in order to proceed.',
    cancelSubscriptionMessage: 'Please confirm in order to proceed.',
    reverseCancelSubscriptionDescription: 'By doing that you will also reverse the cancelled payment for {0}.',
    reverseCancelSubscriptionMessage: 'Please confirm in order to proceed.',
    confirmDeleteDescription: 'There is no way to revert this action once it is completed.',
    paymentSummaryTitle: 'Details summary:',
    401: 'Session expired. Please login again',
    no_data: 'No available data',
    no_data_for_period: 'No available data for selected period',
    payFineTitle: 'Pay fine?',
    payFineDescription: 'Please confirm in order to proceed',
    reversePayFineTitle: 'Reverse payment?',
    reversePayFineDescription: 'Please confirm in order to proceed',
    incidentConfirmReleaseTitle: 'Create release request',
    incidentConfirmReleaseDescription: 'Please confirm in order to proceed',
    incidentCancelTitle: 'Cancel incident',
    incidentCancelDescription: 'Please confirm in order to proceed',
    repatriationConfirmCancelTitle: 'Cancel repatriation request',
    repatriationConfirmCancelDescription: 'Please confirm in order to proceed',
    duration_already_exists: 'Daily card with such parameters already exists. Please remove the old one first before you add a new one.',
    confirmInspectionTitle: 'Wait! Are you sure you want to proceed?',
    confirmInspectionDescription: 'Please confirm in order to proceed.',
    rejectInspectionTitle: 'Wait! Are you sure you want to proceed?',
    rejectInspectionDescription: 'Please confirm in order to proceed.',
    noInspectionForCamera: 'No Inspection For Camera',
    InspectionImageError_timeout: 'No response from SmartALot (timeout)',
    InspectionImageError_api: 'Invalid response from SmartALot',
    min3chars: 'Please enter at least 3 characters.',
    select_park_area: 'Please select park zone first to draw',
    outside_bounds: 'Outside of allowed bounds!'
  },
  tableRows: {
    minutes: 'minutes',
    day: 'day',
    days: 'days',
    translated: 'Translated',
    app_manual: 'Manual',
    app_ocr: 'Image',
    active: 'Active',
    inactive: 'Inactive',
    pending_payment: 'Pending payment',
    pending_review: 'Pending review',
    declined: 'Declined',
    expired: 'Expired',
    canceled: 'Cancelled',
    electric_car: 'Electric car',
    municipal_car: 'Municipal car',
    other: 'Other',
    repatriation: 'Repatriation',
    tire_block: 'Tire block',
    administrative: 'Administrative',
    not_paid: 'Not paid',
    paid: 'Paid',
    discount: 'Discount',
    payment: 'Payment',
    durationHoursAndMinutes: '{0} hours and {1} minutes',
    durationHours: '{0} hours',
    durationMinutes: '{0} minutes',
    paid_with_cash: 'Paid with cash',
    paid_with_wire: 'Paid with card',
    cash: 'Paid with cash',
    wire: 'Paid with card'
  },
  select: {
    active: 'Active',
    inactive: 'Inactive',
    pending_review: 'Pending review',
    pending_payment: 'Pending payment',
    declined: 'Declined',
    expired: 'Expired',
    canceled: 'Cancelled',
    not_paid: 'Not paid',
    paid: 'Paid',
    electric_car: 'Electric car',
    municipal_car: 'Municipal car',
    other: 'Other',
    repatriation: 'Repatriation',
    tire_block: 'Tire block',
    administrative: 'Administrative'
  },
  notification: {
    500: 'Oops, something went wrong',
    success_approve_subscription: 'Subscription has been successfully approved',
    success_cancel_subscription: 'Subscription has been successfully cancelled',
    success_cancel_fine: 'Fine has been successfully cancelled',
    success_cancel_payment: 'Payment has been successfully cancelled',
    success_password_reset_request: 'Email with password reset link has been sent to your email',
    success: 'Success',
    success_payment: 'The payment is successful',
    success_export: 'Success! Please check your email.',
    fail_export: 'Oops, something went wrong! The export was not successful.',
    subscription_payment_invalid_status: 'Cannot display payments for this subscription status',
    inspection_confirmed: 'Inspection Confirmed',
    inspection_rejected: 'Inspection Rejected'
  },
  tooltip: {
    delete_duration: 'Delete duration',
    AllowProrateDuration: 'Allow prorate duration',
    total_amount: 'Total amount',
    service: 'Service'
  },
  chart: {
    pending_confirmation: 'Pending Confirmation',
    finalized: 'Finalized',
    failed: 'Failed',
    success: 'Success',
    fine: 'Fine',
    parking: 'Parking',
    parking_daily: 'Daily Parking',
    parking_hourly: 'Hourly Parking',
    blocked: 'Blocked',
    business_subscription: 'Business Subscription',
    in_tolerance: 'In Tolerance',
    block_request_created: 'Expired Tolerance, Waiting for Tyreblock',
    block_request_taken: 'Tyreblock Request Taken',
    expired_tolerance_waiting_for_tyreblock: 'Expired Tolerance, Waiting for Tyreblock',
    request_for_release: 'Request for Release',
    release_request_created: 'Release Request Created',
    taken_request_for_release: 'Taken Request for Release',
    release_request_taken: 'Release Request Taken',
    block_request_initialized: 'Block Request Initialized',
    expired: 'Expired',
    unblocked: 'Unblocked',
    subscription: 'Subscription',
    repatriation_request_created: 'Repatriation Request Created',
    repatriation_request_taken: 'Repatriation Request Taken',
    repatriation_on_the_way: 'Repatriation on the Way',
    repatriation_at_parking: 'Repatriation at Parking',
    repatriation_released: 'Repatriation Released',
    valid_check_with_payment: 'Valid Check With Payment',
    in_tolerance_without_payment: 'In Tolerance Without Payment',
    pending: 'Pending',
    confirmed: 'Confirmed',
    auto_confirmed: 'Auto Confirmed',
    rejected: 'Rejected',
    auto_rejected: 'Auto Rejected',
    has_payment: 'Has Payment',
    employees_total: 'Total Employees',
    employees_inspectors: 'Inspectors',
    employees_tyreblockers: 'Tyreblockers',
    employees_repatriators: 'Repatriators',
    employees_cameras: 'Cameras',
    actions_inspections: 'Inspections',
    actions_inspections_camera: 'Inspections by Cameras',
    actions_tyre_blocks_requested: 'Tyreblock Requests',
    actions_tyre_blocks_executed: 'Tyreblocks Executed',
    actions_tyre_blocks_requested_camera: 'Tyreblock Requests (Cameras)',
    actions_tyre_blocks_executed_camera: 'Tyreblocks Executed (Cameras)',
    actions_repatriations_requested: 'Repatriation Requests',
    actions_repatriations_executed: 'Repatriations Executed',
    actions_repatriations_requested_camera: 'Repatriation Requests (Cameras)',
    actions_repatriations_executed_camera: 'Repatriations Executed (Cameras)',
    payment_parking: 'Parking',
    payment_fines: 'Fines',
    payment_subscriptions: 'Subscriptions',
    employees_inspectors_total: 'Total Inspectors',
    employees_inspectors_inspections: 'Inspections',
    employees_inspectors_requests: 'Requests',
    employees_inspectors_fines: 'Fines',
    employees_inspectors_no_actions: 'No Actions',
    employees_tyre_blockers_total: 'Total Tyreblockers',
    employees_tyre_blockers_blocked: 'Blocked',
    employees_tyre_blockers_canceled: 'Canceled',
    employees_repatriators_total: 'Total Repatriators',
    employees_repatriators_blocked: 'Blocked',
    employees_repatriators_canceled: 'Canceled',
    compare_inspectors_inspections: 'Inspections',
    compare_inspectors_requests: 'Requests',
    compare_inspectors_fines: 'Fines',
    compare_inspectors_no_actions: 'No Actions',
    compare_tyre_blockers_blocked: 'Blocked',
    compare_tyre_blockers_canceled: 'Canceled',
    compare_repatriators_blocked: 'Blocked',
    compare_repatriators_canceled: 'Canceled',
    active: 'Active',
    ready: 'Ready',
    canceled: 'Canceled',
    inactive: 'Inactive',
    pending_payment: 'Pending Payment',
    pending_review: 'Pending Review',
    no_checks: 'No Checked Cars',
    PrintImages: 'Print Images'
  },
  label: {
    development: 'dev',
    production: 'prod',
    Inspector: 'Employees',
    InspectorNoPrinter: 'Employees - no printer',
    Stores: 'Stores',
    confirmPayment: 'Please confirm payment?',
    welcome: 'Welcome to {0}',
    mobzzo_admin_panel: 'Admin panel dashboard',
    dashboard_message: 'The best parking solutions to ensure efficient parking management.',
    reject_reason: 'Reject reason'
  },
  MonthlyEmployeesStatistics: {
    inspectors: 'Inspectors',
    employee: 'Employee',
    compare: 'Compare',
    P: 'P',
    Z: 'Z',
    G: 'G',
    BD: 'BD',
    total_employees: 'Total Employees',
    total: 'Total',
    blocked: 'Blocked',
    canceled: 'Canceled',
    auto_canceled: 'Auto Canceled',
    repatriation: 'Repatriation',
    tyre_blockers: 'Tyre Blockers'
  }
}
