export default function ({ app, req }: any) {
  let url = ''
  if (process.server) {
    url = req.headers.host + req.url
  } else if (process.client) {
    url = window.location.href
  }

  const isMacedonian = url.includes('mk')
  app.i18n.locale = isMacedonian ? 'mk' : 'bg'
  app.i18n.fallbackLocale = isMacedonian ? 'mk' : 'bg'
}
