import { mutationTree, actionTree, getterTree } from 'typed-vuex'
import { ResourceType, UserPermissionsType } from '~/types/response/roles'

type FeatureType = {
  IsActive: boolean
  Name: string
}

type ConfigType = {
  Currency: string
  MobileCarDomain: string
  Features: FeatureType[],
  CityID: number | null,
}

type UserType = {
  Email?: string
  FirstName?: string
  LastName?: string
}

export const state = () => ({
  config: null as null | ConfigType,
  Resources: null as null | ResourceType[],
  user: null as null | UserType
})

export const mutations = mutationTree(state, {
  SET_CONFIG (state, payload) {
    state.config = payload
  },
  SET_RESOURCES (state, payload) {
    state.Resources = payload
  },
  SET_USER_INFO (state, payload) {
    state.user = Object.assign({}, payload)
  }
})

export const actions = actionTree({ state, mutations }, {
  async loadConfig ({ commit }) {
    const { data } = await this.$axios.get('/config/')
    commit('SET_CONFIG', data)
  },
  async loadPermissions ({ commit }) {
    const { data } = await this.$axios.get('/acl/user-resources/')
    commit('SET_RESOURCES', data.Resources)
  }
})

export const getters = getterTree(state, {
  userPermissions (state) {
    const obj: UserPermissionsType = {}

    if (state.Resources) {
      state.Resources.forEach((el: ResourceType) => {
        const permissionsArr = el.Permissions.map(e => e.Name)
        // @ts-ignore
        obj[el.Name] = permissionsArr
      })
    }

    return obj
  }
})
